<template>
  <div
    class="relative flex flex-col group rounded-3xl overflow-hidden bg-white dark:bg-neutral-900 h-full"
  >
    <NuxtLink
      :to="localePath('/articles/' + post.slug )"
      class="block flex-shrink-0 relative w-full rounded-t-3xl overflow-hidden z-10 aspect-w-4 aspect-h-3 cursor-pointer"
    >
      <div class="w-full h-full">
        <NuxtImg
          :src="post.thumbnail || '/icons/loading.svg'"
          provider="s3"
          placeholder="/icons/loading.svg"
          loading="lazy"
          :alt="post.slug"
          class="object-cover absolute inset-0 w-full h-full"
        />
      </div>
    </NuxtLink>
    <div class="p-4 flex flex-col justify-between h-full space-y-3">
      <div
        class="inline-flex items-center flex-wrap text-neutral-800 dark:text-neutral-200 leading-none text-xs"
      >
        <NuxtLink
          class="relative flex items-center space-x-2"
          :to="localePath('/author/' + post?.author_username)"
        >
          <div
            class="relative flex-shrink-0 inline-flex items-center justify-center overflow-hidden text-neutral-100 uppercase font-semibold shadow-inner rounded-full h-7 w-7 text-sm ring-1 ring-white dark:ring-neutral-900"
          >
            <NuxtImg
              :src="post.author_avatar"
              provider="s3"
              placeholder="/icons/loading.svg"
              loading="lazy"
              class="object-cover absolute inset-0 w-full h-full"
              :alt="'avatar-author-' + post.author_nickname"
            />
            <span class="uppercase">{{ post.author_name?.charAt(0) }}</span>
          </div>
          <span
            class="block text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white font-medium"
            >{{ post.author_nickname }}</span
          >
        </NuxtLink>
        <span
          class="text-neutral-500 dark:text-neutral-400 mx-[6px] font-medium"
          >·</span
        >
        <span class="text-neutral-800 dark:text-neutral-400 font-normal">
          {{ dayjs(post.post_date).format("MMM DD") }}
        </span>
      </div>
      <h3
        class="block text-base font-semibold text-neutral-900 dark:text-neutral-100"
      >
        <BlogLayerLink :post="post"></BlogLayerLink>
      </h3>

      <div class="flex items-end justify-between mt-auto">
        <div>
          <NuxtLink
            class="relative items-center min-w-[68px] rounded-full text-neutral-6000 bg-neutral-50 transition-colors dark:text-neutral-200 dark:bg-neutral-800 hover:bg-primary-50 dark:hover:bg-primary-100 hover:text-primary-600 dark:hover:text-primary-500 flex px-3 h-8 text-xs"
            :title="$t('comments')"
            :to="localePath('/articles/' + post.slug + '#comments')"
          >
            <IconComment />
            <span class="ml-1 text-neutral-900 dark:text-neutral-200">{{
              parseInt(post.comments_count + "")
            }}</span>
          </NuxtLink>
        </div>
        <!-- <button
          class="relative rounded-full flex items-center justify-center h-8 w-8 bg-neutral-50 hover:bg-neutral-100 dark:bg-neutral-800 dark:hover:bg-neutral-700"
          :title="$t('save_to_read_list')"
        >
          <IconBookmark class="w-[18px] h-[18px]" />
        </button> -->
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { type PostShort } from "~/types/Post";
import "dayjs/locale/en";
import "dayjs/locale/vi";

const props = defineProps({
  post: {
    type: Object as () => PostShort,
    required: true,
  },
});
const { locale } = useI18n();
const localePath = useLocalePath();
const dayjs = useDayjs();
dayjs(locale.value);
</script>
